import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.css";
import "tachyons";

// Stores
import AuthStore from "./stores/AuthStore";

// Styling
import { ThemeProvider } from "styled-components";

// icons
import { library } from "@fortawesome/fontawesome-svg-core";

import { faUndo, faTrashAlt, faLock } from "@fortawesome/free-solid-svg-icons";

import { createHashRouter, RouterProvider } from "react-router-dom";

import {
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-regular-svg-icons";
import InfoPage from "pages/info/InfoPage";
import ProgramPage from "pages/program/ProgramPage";
import SignupPage from "pages/SignupPage";
import PasswordResetPage from "pages/PasswordResetPage";
import Rommelmarkt from "pages/market/Rommelmarkt";
import PayedPage from "pages/PayedPage";
import MarketPage from "pages/market/MarketPage";
import RommelmarktOverview from "pages/market/RommelmarktOverview";
import LoginPage from "pages/LoginPage";
import AccountPage from "pages/account/AccountPage";
import AdminPage from "pages/admin/AdminPage";
import SaturdayPage from "pages/program/SaturdayPage";
import SundayPage from "pages/program/SundayPage";
import FridayPage from "pages/program/FridayPage";
import HomePage from "pages/HomePage";
import RedirectPage from "pages/RedirectPage";

library.add(faUndo, faTrashAlt, faEnvelope, faLock, faEye, faEyeSlash);

const theme = {
  primaryColor: "#2c4a76",
  secondaryColor: "#100a2b",
  hightLightColor: "#00e8cb",
  primary100: "#9f9caa",
  primary200: "#706a81",
  primary300: "#574f6b",
  primary400: "#403957",
  primary700: "#1c1636",
  primary900: "#11082d",
  gray30: "#4D4D4D",
  gray100: "#f3f2f4",
  gray200: "#edecf0",
  gray300: "#e7e6ea",
  gray400: "#c3c2ca",
  accentAlt100: "#adaaf8",
  accentAlt300: "#635cf7",
  accentAlt400: "#6f67b7",
  accentAlt500: "#584dab",
  accentAlt700: "#4236a1",
  accent: "#d6b959",
  accentLight: "#ebdcac",
  successColor: "#4ca760",
  successLight: "#a6d3b0",
  warning: "#d16d3a",
  warningLight: "#e6b095",
  errorColor: "#be4a49",
  errorLight: "#dfa5a4",
};

const router = createHashRouter([
  {
    path: "/*",
    element: <App />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "program",
        element: <ProgramPage />,
        children: [
          {
            path: "friday",
            element: <FridayPage />,
          },
          {
            path: "saturday",
            element: <SaturdayPage />,
          },
          {
            path: "sunday",
            element: <SundayPage />,
          },
        ],
      },
      {
        path: "info",
        element: <InfoPage />,
      },
      {
        path: "signup",
        element: <SignupPage />,
      },
      {
        path: "reset",
        element: <PasswordResetPage />,
      },
      {
        path: "payed",
        element: <PayedPage />,
      },
      {
        path: "reserve",
        element: <Rommelmarkt />,
      },
      {
        path: "market",
        element: <RommelmarktOverview />,
      },
      {
        path: "marketAdmin",
        element: <MarketPage />,
      },
      {
        path: "login",
        element: <LoginPage />,
      },
      {
        path: "account",
        element: <AccountPage />,
      },
      {
        path: "admin",
        element: <AdminPage />,
      },
      {
        path: "tickets",
        element: <RedirectPage />,
      },
    ],
  },
]);

ReactDOM.render(
  <StrictMode>
    <AuthStore>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </AuthStore>
  </StrictMode>,

  document.getElementById("root")
);
