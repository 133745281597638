import React, { useState, useEffect } from "react";

import { useAuthState } from "stores/AuthStore";

import { UpdateStandplaats } from "api/calls";

// Styling
import styled from "styled-components";

const MainCard = styled.div`
  display: flex;
  flex-flow: column;
  border: 1px solid black;
  border-radius: 2px;
  margin-bottom: 15px;
  margin-right: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  background-color: ${(props) =>
    props.color === 0
      ? "#00ff0055"
      : props.color === 1
      ? "#ff000055"
      : "#ffff0099"};
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-flow: row;
  padding: 10px;
`;

const InfoText = styled.div`
  flex-basis: 33%;
`;

const SwitchButton = styled.button``;

const StandplaatsCard = ({ standplaats }) => {
  const { AuthState } = useAuthState();
  const { token } = AuthState;

  const [localState, setLocalState] = useState(0);

  useEffect(() => {
    setLocalState(standplaats.Bezet);
  }, [standplaats]);

  return (
    <MainCard color={localState}>
      <InfoWrapper>
        <InfoText>{standplaats.Standplaats}</InfoText>
        <InfoText>{standplaats.Straat}</InfoText>
        {standplaats.Bezet !== 2 && (
          <SwitchButton
            onClick={() => {
              const newState = localState === 1 ? 0 : 1;
              UpdateStandplaats(standplaats.Standplaats, newState, token);
              setLocalState(newState);
            }}
          >
            Switch
          </SwitchButton>
        )}
      </InfoWrapper>
    </MainCard>
  );
};

export default StandplaatsCard;
